<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt24px">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="left" label-width="108px">
          <ayl-main title="基本信息" :isCollapse="false">
            <div slot="main" class="p24px">
              <el-form-item label="所属企业" prop="company">
                <el-select v-model="form.company" placeholder="请选择">
                  <el-option v-for="(item, idx) in companyList"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="SIM卡号" prop="simNumber">
                <el-input v-model="form.simNumber" maxlength="14"></el-input>
              </el-form-item>
              <el-form-item label="运营商" prop="serviceProvider">
                <el-select v-model="form.serviceProvider" placeholder="请选择">
                  <el-option v-for="(item, idx) in $enums_hw.ServiceProvider.list"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="套餐" prop="pricePackage">
                <el-input v-model="form.pricePackage" maxlength="16"></el-input>
              </el-form-item>
              <el-form-item label="提醒天数(天)" prop="alarmDay">
                <el-input v-model="form.alarmDay" placeholder="输入1-30的整数" maxlength="2"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" prop="expireDate">
                <el-date-picker
                        v-model="form.expireDate"
                        clearable
                        value-format="timestamp"
                        type="date"
                        placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="hanldBidId"
                ></el-cascader>
              </el-form-item>
              
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea"
                          maxlength="64"
                          :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入内容" v-model="form.remark">
                </el-input>
              </el-form-item>
            </div>
          </ayl-main>
        </el-form>
      
    <div class="tac mt40px">
        <el-button type="success" :loading="submitLoading" class="btn-success-hw" @click="submitCar">保存</el-button>
        <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const validateSimNumber = (rule, value, callback) => {
        if (!value||value.length>14||value.length<11) return callback(new Error('请输入11—14位SIM卡号'))
        if (!(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请输入11—14位SIM卡号'))
        } else {
          callback()
        }
      }
      const validateAgentPhone = (rule, value, callback) => {
        if(!value) return callback()
        if (value.length<6||value.length>13) return callback(new Error('请输入正确的联系电话'))
        if (!(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请输入正确的联系电话'))
        } else {
          callback()
        }
      }
      const validateAlarmDay = (rule, value, callback) => {
        if (!value) return callback(new Error('请输入提醒天数'))
        if (value > 30 || value < 1 || !(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请正确输入1-30之间的整数'))
        } else {
          callback()
        }
      }
      return {
        nav: [
          {name: "新增SIM卡"},
        ],
        // status: '未启用',
        bidList: [],     //标段名称
        form: {
          company: null,
          simNumber: null,
          serviceProvider: null,
          pricePackage: null,
          bidId: null,
          expireDate: null,
          remark: null,
          alarmDay: null,
        },
        rules: {
          company: {required: true, message: '请选择所属公司', trigger: 'change'},
          simNumber: {required: true, validator: validateSimNumber, trigger: 'blur'},
          serviceProvider: {required: true, message: '请选择运营商', trigger: 'change'},
          alarmDay: {required: true, validator: validateAlarmDay, trigger: 'blur'},
          pricePackage: [{required: true, trigger: 'blur', message: '请输入套餐名称'},
            // {validator: this.$validator.isContainBlank}
            ],
          bidId: {
            required: true,
            message: "请选择所属标段",
            trigger: "change"
        },
          expireDate: {required: true, message: '请选择时间', trigger: 'change'},
          remark: {message: '请输入2-64位的备注', trigger: 'blur'},
          // agent: {message: '请输入2-32位的代理人', trigger: 'blur', min: 2, max: 32},
          // agentPhone: {trigger: 'blur', validator: validateAgentPhone},
        },
        submitLoading: false,
        companyList: []
      }
    },
    methods: {
      //获取所属标段
      hanldBidId(arr) {
        this.form.bidId = arr[1];
      },
      submitCar(isGoNext){
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            await this.$api_hw.simManagement_insertSimInfo({
              simInfoVOIOV: this.form
            })
            this.$notify({
              title: '成功',
              message: '新增SIM信息成功',
              type: 'success'
            });
            BUS.$emit(BUSEVENT.REFRESH_SIM_MANAGEMENT);
            if (isGoNext !== true) {
              this.$refs["form"].resetFields();
              this.onClose();
            } else {
              this.$refs["form"].resetFields();
              
            }
          } catch (e){}
          this.submitLoading = false
        })
      },
      onSubmitAndNext() {
        this.submitCar(true);
      },
      onClose() {
        BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
    },
    async mounted () {
      let companyList = await this.$api_hw.districtInfo__getTenantCompanyInfo({})
      this.companyList = [companyList]
      this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
    }
  }
</script>

<style lang='sass' scoped>
</style>
